$rootPath: "/v40/23179";
// Override variables in the default theme
$brand-primary: #146835;
$brand-info: #146835;

@import "Theme";

.btn-primary {
    background-color: #146835;

    &:active, &:hover, &:focus {
        background-color: darken(#146835, 10%);
        border-color: darken(#146835, 12%);
    }
}

.topnavbar {
    background-color: #fff;
    background-image: none;

    .navbar-nav {
        > .open {
            > a {
                color: $brand-primary;
            }
        }

        > li {
            > .navbar-text {
                color: $brand-primary;
            }

            > a {
                color: $brand-primary;
            }
        }
    }
}
